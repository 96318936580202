import { Content, asImageSrc } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import cn from 'classnames';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './Testimonial.module.scss';

/**
 * Props for `Testimonial`.
 */
export type TestimonialProps = SliceComponentProps<Content.TestimonialSlice>;

/**
 * Component for "Testimonial" Slices.
 */
const Testimonial = ({ slice }: TestimonialProps): JSX.Element => {
  const { background, quote_text, name_designation_line_1, name_designation_line_2, logo, image } = slice.primary;
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])
  if (slice.variation === 'testimonialWithBoldParts') {
    return (
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className={cn(styles.root, 'wide-container', styles[bg || background], styles[slice.variation], 'color-changing-section')}
        data-background={background}
      >
        <div
          className={classNames(styles.content)}
        >
          {/* {asImageSrc(logo) && <PrismicNextImage field={logo} />} */}
          <PrismicRichText  field={quote_text}  applyStyles={false}/>
          {asImageSrc(image) && (
            <p className={styles.image}>
              <PrismicNextImage field={image} width={150} height={150} />
            </p>
          )}
          <footer>
            <span data-testid="name_designation_line_1" className={styles.author}>
              {name_designation_line_1}
            </span>
            <span data-testid="name_designation_line_2" className={styles.designation}>
              {name_designation_line_2}
            </span>
          </footer>
        </div>
      </section>
    );
  }
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={cn(styles.root, 'wide-container', styles[background])}
    >
      <div
        className={classNames(styles.content, {
          [styles.lightText]: background === 'dark',
        })}
      >
        {/* {asImageSrc(logo) && <PrismicNextImage field={logo} />} */}
        <PrismicRichText  field={quote_text}  applyStyles={false}/>
        {asImageSrc(image) && (
          <p className={styles.image}>
            <PrismicNextImage field={image} width={150} height={150} />
          </p>
        )}
        <footer>
          <span data-testid="name_designation_line_1" className={styles.author}>
            {name_designation_line_1}
          </span>
          <span data-testid="name_designation_line_2" className={styles.designation}>
            {name_designation_line_2}
          </span>
        </footer>
      </div>
    </section>
  );
};

export default Testimonial;
